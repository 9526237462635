import React from 'react'
import { graphql } from 'gatsby'
import {
    mapEdgesToNodes,
    filterOutDocsWithoutSlugs,
    filterOutDocsPublishedInTheFuture
} from '../lib/helpers'
import Layout from '../containers/layout'
import SEO from '../components/seo'


import BookingFormAnnexeOnlyPdf from '../pdf/BOOKING FORM ANNEXE ONLY.pdf'
import BookingFormBothRoomsPdf from '../pdf/BOOKING FORM BOTH ROOMS.pdf'
import BookingFormMainHallPdf from '../pdf/BOOKING FORM MAIN HALL.pdf'
import HiringConditionsBothRoomsPdf from '../pdf/HIRING CONDITIONS BOTH ROOMS.pdf'

export const query = graphql`
  query BookingInformationQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      address
    }    
  }
`

const Downloads = props => {
    const { data, errors } = props

    if (errors) {
        return (
            <Layout>
                <GraphQLErrorList errors={errors} />
            </Layout>
        )
    }

    const site = (data || {}).site

    if (!site) {
        throw new Error(
            'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
        )
    }

    return (
        <Layout>
            <SEO
                title={site.title}
                description={site.description}
                keywords={site.keywords}
            />
            {/* {site.address} */}
            <section class="section">
                <div class="container">
                    <article>
                        <h3>Downloads</h3>
                        <a href={BookingFormAnnexeOnlyPdf}>Booking form: Annexe</a><br />
                        <a href={BookingFormBothRoomsPdf}>Booking form: Both Rooms</a><br />
                        <a href={BookingFormMainHallPdf}>Booking form: Main Hall</a><br />
                        <a href={HiringConditionsBothRoomsPdf}>Hiring Conditions for Both Rooms</a>
                        {/* <a href={bookingFormPDF}>Booking Form</a><br />
                        <a href={conditionsPDF}>Main Conditions of Hire</a> */}
                    </article>
                </div>
            </section>
        </Layout>
    )
}

export default Downloads
